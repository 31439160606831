.CustomPrintTable th{
  width: 100%;
}

/* 匯出檔案邊際 */
.CustomPrintTable_Outter {
  padding: 20px;
}

/* printTable 畫線 */
.CustomPrintTable_Line {
  border-top: 1px solid #82C200;
  margin-bottom: 20px; 
  height: 10px
}

/* 檔案標頭 */
.CustomPrintTable_FileHeader {
  font-size: 35px; 
  color: #82C200; 
  font-weight: bold; 
  margin: 10px; 
  text-align: center;
}

/* 一般標題欄 */
.CustomPrintTable_header {
    font-size: 11px;
    display: -webkit-box;
    display: -webkit-flex;
    width: 754px
}

/* 格局Inner */
.CustomPrintTable_Inner {
  width: 377px;
}

/* Flex Start 設定 */
.CustomPrintTable_FileStart {
  display: -webkit-box;
  display: -webkit-flex;
}

/* Flex Start 設定 - 左 */
.CustomPrintTable_FileStart_Left {
  width: 189px;
}

/* Flex Start 設定 - 右 */
.CustomPrintTable_FileStart_Right {
  width: 565px;
}

/* Flex End 設定 */
.CustomPrintTable_FileEnd {
    display: -webkit-box;
    display: -webkit-flex;
    width: 754px;
}

/* Flex End 設定 - 左 */
.CustomPrintTable_FileEnd_Left {
  width: 282px;
  margin-right: auto;
}

/* Flex End 設定 - 右 */
.CustomPrintTable_FileEnd_Right {
  width: 471px;
}

/* Flex Center 設定 */
.CustomPrintTable_FileCenter {
    display: -webkit-box;
    display: -webkit-flex;
    width: 754px;
}

.CustomPrintTable_FullWidth {
  width: 754px;
}

.CustomPrintTable th.TenPercent {
    width: 75px;
}

.CustomPrintTable th.FifteenPercent {
    width: 113px
}

.CustomPrintTable th {
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    text-align: left;
    border-collapse: collapse;
    padding: 16px;
    background-color: #585A5D;
    word-break: break-all;
    word-wrap: break-word;
}

.CustomPrintTable td {
    font-size: 16px;
    border-bottom: 1px solid #f0f0f0;
    border-collapse: collapse;
    padding: 15px 5px;
    word-break: break-all;
    word-wrap: break-word;
}

.CustomPrintTable tr {
    font-size: 10px;
    border-bottom: 1px solid #f0f0f0;
    border-collapse: collapse;
    padding: 15px 5px;
    page-break-inside: avoid;
    page-break-after: auto;
}

.CustomPrintTable_footer {
  width: 754px;
  font-size: 11px;
}

.CustomPrintTable_footerFlex {
  display: -webkit-box;
  display: -webkit-flex;
}

.CustomPrintTable_footerSpaceBwtn_40 {
    margin-top: 40px;
    /* display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    flex-direction: row; */
}

.CustomPrintTable_footerSpaceBwtn_40_TenPercent {
  margin-right: auto;
  width: 75px
}

.CustomPrintTable_footerSpaceBwtn_40_TwentyPercent {
  width: 150px
}

.CustomPrintTable_footerSpaceBwtn {
    display: -webkit-box;
    display: -webkit-flex;
} 

.CustomPrintTable_footerSpaceBwtn_Left {
  width: 500px;
}

.CustomPrintTable_footerSpaceBwtn_Right {
  width: 254px;
}

.CustomPrintTable_footerSpaceBwtn_FifteenPercent {
  width: 113px;
}

.CustomPrintTable_footerSpaceBwtn_TwentyPercent {
  width: 150px;
}


.CustomPrintTable_footer_legecy_40 {
  margin-top: 40px;
}

.CustomPrintTable_Term {
  color: #82C200; 
  font-size: 20px; 
  margin-top: 10px
}

.CustomPrintTable_footer_legecy_8 {
  margin-top: 8px;
}

.CustomPrintTable {
    page-break-inside: auto;
    border-collapse: collapse;
}