body {
  .tableText {
    color: $primaryColorHover;
    font-weight: 600;
  }

  .table-row-light {
    background-color: #ffffff;
  }
  .table-row-dark {
      background-color: #efefefa0;
  }

  .ant-table-thead > tr > th {
    text-align: center !important;
  }
  .ant-pagination-item {
    border-color: $gray-color;

    a {
        color: $gray-color-hover;
    }
  }
  .ant-pagination-item-active {
    border-color: $primaryColor;
    a {
      color: $primaryColor;
    }
  }
  .ant-pagination-item:hover {
      border-color: $primaryColorHover;

      a {
          color: $primaryColorHover;
      }
  }
  .ant-pagination-item-link{
    span {
        color: $primaryColor;
    }
  }
  .ant-pagination-item-link:hover {
      span {
          color: $primaryColorHover;
      }
  }
  .ant-pagination-next .ant-pagination-item-link:hover {
    border-color: $primaryColor;
  }
  .ant-pagination-prev .ant-pagination-item-link:hover {
    border-color: $primaryColor;
  }

  .ant-pagination.ant-pagination-mini .ant-pagination-total-text{
    color: #a5a5a5;
  }
  .ant-pagination.ant-pagination-mini .ant-pagination-options {
    margin: -2px 2px;
  }

  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
    border: $primaryColor;
    color: #a5a5a5;
    background-color: rgba(0, 0, 0, 0);
  }

  .ant-table-pagination.ant-pagination {
    margin: 10px 0;
  }

  .ant-table-row-expand-icon{
    color: $white-color;
    background-color: $primaryColor;
    border-radius: 3px;
  }
  .ant-table-row-expand-icon:hover{
    color: $white-color;
    background-color: $primaryColor;
    border-radius: 3px;
  }
  .ant-table-row-expand-icon:focus{
    color: $white-color;
    background-color: $primaryColor;
    border-radius: 3px;
  }
  
  tr.ant-table-expanded-row > td, tr.ant-table-expanded-row:hover > td{
    background: $contentColor;
    color: $textColor;
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: rgba(188, 223, 147, 0.4);
  }
  .ant-table-tbody > tr.ant-table-row-selected:hover > td {
    background: rgba(188, 223, 147, 0.735);
  }
  thead tr th:first-child .ant-checkbox-inner::after {
    background: $primaryColor;

  }
  .ant-table-cell {
    background: rgba(199, 199, 199, 0.2);
    border-top: 1px solid $gray-color;

    .ant-divider-vertical {
      height: 1.7em;
    }

    a{
      color: $primaryColor;
      display: inline-block;
      transition: .3s;
      font-weight: 600;
    }
    a:hover {
      transition: color .9s;
      color: $primaryColorHover;
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
    a.disabled {
      pointer-events: none;
      color: $gray-color-hover;
    }
  }
}