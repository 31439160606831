@mixin btn-border-color($color) {
    background: $color;
    border-color: $color;
}

@mixin btn-color($color) {
    background: $color;
    border: $color;
}

@mixin base-btn($color) {
    @include btn-color($color);
    font-size: $default-font-size;
    font-weight: 600;
    color: $white-color;
}

@mixin menu {
    font-family: "Noto Sans TC" !important;
    letter-spacing: 0.05em;
    color: $white-color;
    font-weight: 700;
}