body {
    .headerlayout{
      background-color: $headerColor;
    }

    .logo {
      float: left;
      color: $primaryColor;
      font-size: 20px;
      font-weight: bold;
      a{
        color: #7DB926;
      }
    }
    .moblieLogo {
      float: right;
      color: $primaryColor;
      font-size: 18px;
      font-weight: bold;
      a{
        color: #7DB926;
      }
    }
    
    .ant-layout-header {
      background-color: $headerColor;
      padding: 0px 25px;
      border-bottom: 1px solid #282828;
  
      .ant-dropdown-link {
        color: $gray-color-hover;
        font-weight: 800;
      }
    
    .navbar__link-separator {
        color: $gray-color-hover;
        font-weight: bold;
        font-size: large;
    }
    
    .headerprofile {
        height: 100%;
        width: 50px;
    }
    
    .headertext{
      font-weight: 800;
      color: $gray-color;
       white-space: nowrap; /*不換行 */
       overflow: hidden;  
       width: 160px;
       text-align: center;
       text-overflow: ellipsis;  /*若文字超過50px，改以...顯示(需搭配hidden才有效)*/
     }
  
      .headerprofile {
        display: flex;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        background-color: rgb(187, 223, 147);
        margin-top: 17px;
        margin-left: 10px;
  
        .photo {
          height: 100%;
          width: 100%;
          border-radius: 50%;
        }
  
        .user {
          margin: auto;
          width: 45%;
          height: 45%
        }
      }
    }
  
  }