div.react-pdf__Page__annotations{
  display: none !important; 
}

div.react-pdf__Page__textContent{
  display: none !important;
}

canvas.react-pdf__Page__canvas{
  width: 100% !important;
  height: 100vh !important;
  box-shadow: 0px 2px 10px 0px #c7c7c7 !important;
}

.pageInfo{
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
  font-weight: 600;
}

@media only screen and (max-width: 480px){
  canvas.react-pdf__Page__canvas{
    height: 100% !important;
  }
}