@import "utils/variable";
@import "base/setting";
@import "utils/mixins";
@import "base/reset";
@import "base/html2pdf";
@import "base/fileviewer";
@import "layout/header";
@import "layout/menu";
@import "components/buttons";
@import "components/radio";
@import "components/switch";
@import "components/table";
@import "components/input";

//master.css
html {
  margin: 0;
  height: auto;
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #ffffff;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: #c7c7c7;
  }
  @media only screen and (min-width: 480px) {
    min-width: 1250px;
  }
  @media only screen and (max-width: 480px) {
    ::-webkit-scrollbar {
      width: 2px;
    }
  }
}

body {
  .reactEasyCrop_CropArea {
    border-radius: 50%;
  }
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 16px !important;
  background-color: $layoutColor !important;
  color: $textColor !important;
  overflow-y: hidden;
  ::selection {
    color: #fff;
    background: $green-color;
  }
}

.wigreen {
  color: $green-color;
}

.CustomPrintTable {
  width: 100%;
  th {
    font-size: 11px;
    font-weight: 500;
    color: #fff;
    text-align: left;
    border-collapse: collapse;
    padding: 16px;
  }
  td {
    font-size: 10px;
    border-bottom: 1px solid #f0f0f0;
    border-collapse: collapse;
    padding: 15px 5px;
  }
  &_keeptogether {
    page-break-inside: avoid;
    break-inside: avoid;
  }
  &_header {
    font-size: 11px;
  }
  &_footer {
    font-size: 11px;
  }
}

.ant-tabs-tab-btn {
  color: #777777;
  text-shadow: 0 0 0.25px currentcolor;
}
.ant-tabs-tab-btn:hover {
  color: #7db926;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #7db926;
  text-shadow: 0 0 0.25px currentcolor;
}

.ant-tabs-tab {
  flex-grow: 1;
  margin-right: 0px;
  width: 80%;
  text-align: center;
  // display: flex;
  justify-content: left;
  color: #7db926;
}

.ant-card-body {
  padding: 0px;
}

.switch-style {
  &.ant-switch {
    // background-color: #7db926 !important;
    background-image:none !important;
    background-color: #7db926 !important;
    .ant-switch-inner {
      color: rgb(255, 255, 255);
    }
  }

  &.ant-switch-checked {
    background-color: #7db926 !important;
    .ant-switch-inner {
      color: rgb(255, 255, 255);
    }
  }
}
.ant-layout, .ant-layout * {
  box-sizing: border-box;
  // margin-left: auto;
}