body{
    .ant-btn-primary {
        background: $primaryColor;
        border: $primaryColor;
        font-weight: 900;
        color: $white-color;
        height: 45px;
        border-radius: 15px;
        width: 100%;
    }
    .ant-btn-primary:hover {
        background: $primaryColorHover;
        border: $primaryColor;
        color: $white-color;
    }

    .ant-btn-primary:focus {
        background: $primaryColorHover;
        border: $primaryColor;
        color: $white-color;
    }
    .ant-btn-default {
        background: $gray-color;
        border: $primaryColor;
        font-weight: 900;
        color: $white-color;
        height: 45px;
        border-radius: 15px;
        width: 100%;
    }
    .ant-btn-default:hover {
        background: $gray-color-hover;
        border: $primaryColor;
        color: $white-color;
    }

    .ant-btn-default:focus {
        background: $gray-color-hover;
        border: $primaryColor;
        color: $white-color;
    }
}