body {
    .ant-layout-sider{
        background-color: $menuColor;
        // box-shadow: 0 0 10px #bdbdbd;
    }
    
    .ant-menu.ant-menu-dark,
    .ant-menu-dark .ant-menu-sub,
    .ant-menu.ant-menu-dark .ant-menu-sub {
        @include menu;
    }

    .ant-menu-dark .ant-menu-item,
    .ant-menu-dark .ant-menu-item-group-title,
    .ant-menu-dark .ant-menu-item>a,
    .ant-menu-dark .ant-menu-item>span>a {
        @include menu;
    }

    .ant-menu-dark .ant-menu-item:hover,
    .ant-menu-dark .ant-menu-item-active,
    .ant-menu-dark .ant-menu-submenu-active,
    .ant-menu-dark .ant-menu-submenu-open,
    .ant-menu-dark .ant-menu-submenu-selected,
    .ant-menu-dark .ant-menu-submenu-title {
        @include menu;
    }

    .ant-menu.ant-menu-dark,
    .ant-menu-dark .ant-menu-sub,
    .ant-menu.ant-menu-dark .ant-menu-sub {
        background: $menuColor;
    }

    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: $primaryColorHover;
    }

    .profile {
        // background-color: #d0d0d0;
        padding-left: 20px;
        width: 100%;
        height: 150px;

        .image {
            border: solid 3px #fff;
            display: flex;
            border-radius: 50%;
            height: 70px;
            width: 70px;
            background-color: rgb(187, 223, 147);

            .photo {
                height: 100%;
                width: 100%;
                border-radius: 50%;
            }

            .user {
                margin: auto;
                width: 50%;
                height: 50%
            }
        }
    }
}