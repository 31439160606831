body {
  .ant-select{
    width: 100%;
    font-size: 16px;
  }

  // .ant-select-disabled{
  //   color: rgb(0 0 0 / 85%);
  // }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: #685f5f !important;
  }

  .ant-select-selection-item-content {
    color: rgb(0 0 0 / 85%);
  }
  .ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #ffffff;
  }

  .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-color: $primaryColor;
  }
  .ant-input-affix-wrapper-lg {
    padding-left: 20px;
  }
  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    left:0px;
  }
  .ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
    margin-left: 20px;
    @include base-btn($primaryColor);
    border-radius: 15px;
    width: 130px;
  }
  .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-radius: 30px;
  }
  .wi-calender {
    .ant-picker-input>input {
      text-align: center !important;
    }
    .ant-picker-suffix {
      display: none !important;
    }
  }
  .wi-m-calender {
    .ant-tag {
      padding-left: 2px !important;
      line-height: 15px !important;
      margin-right: 0px !important;
      font-size: 8px !important;
      width: 100% !important;
      border: none !important;
      cursor: pointer !important;
    }
  }

  .wi-calender {
    .ant-tag {
      padding-left: 2px !important;
      font-size: 12px !important;
      line-height: 17px !important;
      margin-right: 0px !important;
      min-width: 100% !important;
      border: none !important;
      cursor: pointer !important;
    }
  }

  .more-calender {
    .ant-tag {
      padding: 5px !important;
      font-size: 16px !important;
      line-height: 20px !important;
      margin: 5px !important;
      min-width: 100% !important;
      border: none !important;
      cursor: pointer !important;
    }
  }
  .calender-content{
    .ant-picker-calendar-full .ant-picker-panel {
      .ant-picker-calendar-date {
        height: 130px !important;
      }
      .ant-picker-calendar-date-content {
        line-height: 1.5 !important;
      }
    }
  }
  .calender-content-m{
    .ant-picker-calendar-full .ant-picker-panel {
      .ant-picker-calendar-date {
        height: 95px !important;
      }
      .ant-picker-calendar-date-content {
        line-height: 1 !important;
      }
    }
  }
  .tag-more {
    background: #fff;
    color: #685f5f;
    font-weight: 700;
    // border-style: dashed;
  }
}
.events {
  margin: 0;
  padding: 0;
}