body {
  .ant-radio-button-wrapper {
    border-radius: 30px !important;
    margin-right: 20px;
    text-align: center;
    background: none;
    border: none;
    margin-right: 20px;
    color: $gray-color;
    letter-spacing: 0.1em;
  }

  .ant-radio-button-wrapper:hover {
      border-radius: 30px !important;
      margin-right: 20px;
      text-align: center;
      background: none;
      border: none;
      color: $gray-color-hover;
      letter-spacing: 0.1em;
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: $white-color;
      background: $primaryColor;
      border-color: $primaryColor;
  }

  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
      color: $white-color;
      background: $primaryColorHover;
      border-color: $primaryColorHover;
  }

  .ant-radio-button-wrapper:first-child {
    border-left: 0px solid $white-color;
    border-radius: 2px 0 0 2px;
  }
  
  .ant-radio-button-wrapper:not(:first-child)::before {
    top: 10px;
    left: -11px;
    height: 30%;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: $white-color;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
    background-color: $white-color;
  }
}