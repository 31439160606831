body {
  .ant-select-item-option-selected{
    background-color: #96e61e25 !important;
  }
  .ant-select-item-option-active {
    background-color: #96e61e79 !important;
  }
  .ant-menu > .ant-menu-item:hover,
  .ant-menu > .ant-menu-submenu:hover,
  .ant-menu > .ant-menu-item-open,
  .ant-menu> .ant-menu-submenu-open {
    background-color: $green-color-hover !important;
  }
  .ant-menu > .ant-menu-item-active,
  .ant-menu> .ant-menu-submenu-active,
  .ant-menu > .ant-menu-item-selected,
  .ant-menu > .ant-menu-submenu-selected {
    background-color: $green-color !important;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #96e61e79 !important;
  }
  .ant-layout {
    background: $layoutColor;
  }
  .ant-layout.ant-layout-has-sider > .ant-layout, .ant-layout.ant-layout-has-sider > .ant-layout-content {
    background: $contentColor;
    color: $textColor;
  }

  .ant-dropdown-menu-item {
    font-weight: 600;
    color: #737070;
  }

  .ant-breadcrumb a {
    color: $primaryColor;
  }

  .ant-breadcrumb a:hover {
    color: $primaryColorHover;
  }
  .ant-breadcrumb li:last-child {
    color: $gray-color-hover !important;
  }
  .ant-breadcrumb-separator{
    color: $gray-color-hover;
  }

  .ant-checkbox-group {
    font-size: 16px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $primaryColorHover;
    border-color: $primaryColorHover;
  }

  .ant-checkbox-disabled .ant-checkbox-inner{
    background-color: #f5f5f5 !important
  }

  .ant-spin-text{
      color: $primaryColor;
  }
  .ant-spin-dot-item {
      background-color: $primaryColor;
  }

  .ant-back-top{
      right: 50px!important;
  }
  .ant-modal-content{
    .ant-btn {
      width: 30%;
    }
  }

  .ant-input-borderless{
    border-bottom: 1px solid #dddddd !important;
  }  
  .ant-layout-sider-zero-width-trigger{
    top: -52px;
    left: 5px;
    background-color: black;
    z-index: 1;
    width: 30px;
    height: 35px;
    // box-shadow: 0px 1.5px 1.5px 0px rgb(102, 102, 102);
  }

  .ant-menu-dark.ant-menu-submenu-popup {
    display: none;
  }
  .ant-layout-sider-zero-width-trigger:hover::after {
    background: none;
  }
  .ant-popover-message{
    font-size: 16px;
  }
  .ant-popover-buttons{
    display: flex;
    justify-content: space-around;
  }
  .ant-popconfirm{
    width: 15%;
  }

  .ant-input[disabled]{
    color: #685f5f !important;
  }
  // li.ant-pagination-options{
  //   display: none;
  // }

  @media only screen and (max-width: 480px){
    // span.anticon.anticon-profile.ant-menu-item-icon{
    //   position: absolute;
    //   left: 16px;
    // }
    span.anticon.ant-menu-item-icon{
      position: absolute;
      left: 16px;
    }
    span.anticon.anticon-home.ant-menu-item-icon{
      position: absolute;
      left: 16px;
    }
    .ant-menu-title-content{
      display: flex;
      justify-content: center;
    }
    .ant-menu-item .ant-menu-item-icon + span, .ant-menu-submenu-title .ant-menu-item-icon + span, .ant-menu-item .anticon + span, .ant-menu-submenu-title .anticon + span{
      padding-right: 14px !important; 
      margin-left: 0 !important;
    }
    li.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child{
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
    li.ant-menu-item-only-child{
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
    li.ant-menu-item{
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
    div.ant-menu-submenu-title{
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
    a{
      color: #7DB926;
      display: inline-block;
      transition: .3s;
      font-weight: 600;
      font-size: 18px;
    }
    .ant-divider{
      border-top: 1px solid rgba(0, 0, 0, 0.5)
    }
    .ant-breadcrumb ol{
      align-items: center;
    }
    .ant-form-item-label > label{
      font-size: 16px;
      font-weight: bold;
    }
    .ant-divider-horizontal.ant-divider-with-text{
      border-top-color : rgba(0, 0, 0, 0.5)
    }
    .ant-popconfirm{
      width: 50%;
    }
  }
}